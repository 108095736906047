import React, {useEffect} from "react";
import {Container} from "react-bootstrap";
import {Link, useParams} from "react-router-dom";
import {toast} from "react-hot-toast";
import {Helmet} from "react-helmet-async";
import {HelmetProvider} from "react-helmet-async";

export default function Post() {

    const params = useParams();
    const slug = params.slug;

    const [post, setPost] = React.useState({});
    const [loading, setLoading] = React.useState(true);

    useEffect(() => {

        fetch(`${process.env.REACT_APP_API_URL}post/${slug}`)
            .then(response => response.json())
            .then(data => {

                if (data.success) {

                    setPost(data.data);
                    setLoading(false);

                } else {

                    toast.error(data.message);

                }

            })
            .catch(error => {
                toast.error("An error occurred. Please try again later.");
            });

    }, [slug]);

    return (
        <HelmetProvider>

            <Helmet>
                <title>{`${post.title ?? 'Post'} | ${process.env.REACT_APP_NAME}`}</title>
                <meta name="description" content={`${post.meta_description ?? 'A post by Areeb Majeed'}`}/>
                <link rel="canonical" href={`${process.env.REACT_APP_URL}blog/${post.slug ?? ''}`}/>
            </Helmet>

            <Container className={"container-small"}>
                <div className={"text-justify"}>

                    <h1 className={"h1 pb-4"}>
                        {loading ? "Loading..." : post.title}
                    </h1>

                    {post.feature_image && <img src={post.feature_image} className={"d-block mx-auto mb-4 mw-100"} alt={post.title}/>}

                    <article>
                        {loading ? "Loading..." : <div dangerouslySetInnerHTML={{__html: post.body}}></div>}
                    </article>

                </div>
            </Container>

        </HelmetProvider>
    );

}